/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/glightbox@2.0.5/dist/css/glightbox.min.css
 * - /npm/slick-carousel@1.8.1/slick/slick.css
 * - /npm/nouislider@14.1.1/distribute/nouislider.min.css
 * - /npm/vue-snotify@3.2.1/styles/material.min.css
 * - /npm/vue-directive-tooltip@1.6.3/dist/vueDirectiveTooltip.css
 * - /npm/vue-search-select@2.9.3/dist/VueSearchSelect.css
 * - /npm/vue2-datepicker@3.7.0/index.css
 * - /npm/@fancyapps/ui@4.0.31/dist/fancybox.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
